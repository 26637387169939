.footer{
    /* background: no-repeat;
    background-image: url(/public/img/footer.png);
    background-size: cover; */
    background: #363844;

}
.footer .container{
    padding: 60px 0 80px;
    display: flex;
   /* display: grid;
   grid-template-columns: 1fr 1.5fr; */
   gap: 162px;
}
.footer_logo{
    transition: 300ms;
    height: 100%;
    display: flex;
    align-items: flex-start;

}
.footer_logo img{
    /* height: 80%; */
    width: auto;
}
.footer_logo:hover{
    transform: scale(0.9);
}
.footer_left{
    display: flex;
    color: #fff;
    align-items: center;
}
.footer_right{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 0;
}
.footer_right h3{
    font-family: "Roboto";
font-size: 16px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
 position: relative;
 color: #fff;
 cursor: pointer;
}
.footer_right h3::after {
    content: "";
    position: absolute;
    border: 2px solid #fff;
    border-top: none;
    border-left: none;
    width: 6px;
    transform: rotateZ(45deg);
    height: 6px;
    right: 10px;
    top: 5px;
}
.footer_right_nav{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: 300ms;
    height: 0px;
}
.footer_right_nav.active{
    height: 200px;
    overflow-y: scroll;
    padding: 10px 0px;
    width: 100%;
}
.footer_right_nav.active::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #363844;
}

.footer_right_nav.active::-webkit-scrollbar
{
  width: 5px;
  background-color: #363844;
}

.footer_right_nav.active::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}
.footer-list_items_link{
font-size: 14px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
transition: 300ms;
}
.footer a:hover{
    color:#FF552F;
    transition: 0.3s;
}
.mapSite {
    display: flex;
    flex-direction: column;
    font-size:  16px;
    font-weight: 700;
}
.ad {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.ad span{
    font-family: "Roboto";
font-size: 12px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF80;


}
.ad a{
font-size: 14px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

}

.footer_all_details {
    position: relative;
}

.footer_right details {
    margin-bottom: 24px;
    cursor: pointer;
}

.footer_right details summary {
    display: flex;
    align-items: center;
    gap: 16px;
}

.footer_text_item_mini {
    position: absolute;
    top: 0;
    left: 260px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

summary::marker {
    content: "";
  }

.mapSite_container {
    display: flex;
    gap: 60px;
}  

.mapSite.footer_link {
    white-space: nowrap;
}

.footer_text_item {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #fff;
    transition: 0.3s;
}

.footer_text_item:hover {
    color: #ff552f;
}

.footer-product[open] > summary .footer_text_item {
    color: #ff552f;
}

.footer_text_item_img {
    opacity: 0;
    transition: 0.3s;
}

.footer-product[open] > summary .footer_text_item_img {
    opacity: 1;
}

.mapSite.footer_link {
    gap: 24px;
}

.footer_main_link {
    transition: 0.3s;
}

.footer_span {
    margin-top: 12px;
}

.footer_main_link_contact {
    margin-bottom: 4px;
}

.footer_text_item_mini a p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    color: #a5a5a5;
    transition: 0.3s;
}

.footer_text_item_mini a p:hover {
    color: #fefefe;
}

/* .footer-menu_link{
font-size: 16px;
font-weight: 700;
line-height: 22px;

}
.footer-menu{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.footer-menu_contact{
    display: block;
    width: 37px;
    height: 37px;
    border: 2px solid #FEFEFE;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    transition: 500ms;
}
.footer-menu_contact:hover{

    animation:  hov 3s;

}


@keyframes hov {
    0% {
        border-left: 2px solid #FF552F;

    }
    25%{
        border-top: 2px solid #FF552F;

    }

    50% {
        border-right: 2px solid #FF552F;

    }
    75%{
        border-bottom: 2px solid #FF552F;

    }
    100% {
        border: 2px solid #FF552F;

    }
}


.footer-menu_contact_box{
    display: flex;
    gap: 20px;
}
.footer-list{
    width: 100%;
}
.footer-list_item{
    width: 100%;
}
.footer-list_item h4{
    width: 100%;
    border-bottom: 2px solid #FCFCFC ;
    color: #FCFCFC;
}
.footer-list_items{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    max-height: 141px;
    margin: 20px 0px;
}
.footer-list_items_link{
    color: #FEFEFE;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    max-height: 320px;
    position: relative;
}
.footer-list_items_link::after {
    content: "";
    width: 0%;
    height: 2px;
    background: #FF552F;
    transition: 300ms;
    position: absolute;
    left: 0;
    bottom: -3px;
}
.footer-list_items_link:hover::after {
    width: 100%;

} */