@media only screen and (max-width : 1600px) {
	.container{
        width: 1399px;
    }
    .slider-left__text-block {
        left: 68px;
        top: 43px;
    }
    .slider {
        height: 630px;
    }
    .header-right__phone {
        font-size: 16px;
    }
    .header-right__button {
        width: 170px;
    }  
    .slider-chek {
        bottom: 30px;
        left: 90px;
    }    
    .katalog-list_item {
       
        width: 99%;
    }
    
    .connection-fos_bodyBox input, .connection-fos_bodyBox textarea {
        height: 50px;
    }

    .connection-fos_bodyBox .ps {
        margin-top: 0;
    }

    .connection-fos_bodyBox {
        gap: 15px;
    }

    .connection-fos_bodyBox .news_button {
        width: 245px;
    }

    .footer_right {
        gap: 70px;
    }
}


@media only screen and (max-width : 1600px) and (min-width : 1100px) {
    .footer_text_item_mini a p {
        white-space: nowrap;
    }
}

@media only screen and (max-width : 1400px) {
    .container{
        width: 1101px;
    }
    .gallari_item {
    
        height: 42vw;
    }
    .gallari_item_boxList {
        grid-gap: 1%;
        grid-template-columns: 18.8% 31% 30% 19.2%;
    }
    .slider-item_img_min {
        height: auto;
        width: 55%;
        right: 59px;
    }
    .slider-left__text_mini {
        font-size: 30px;
    }
    .slider {
        height: 500px;
    }
    .header_slogan {
        font-size: 12px;
    }
    .header_slogan span {
        font-size: 24px;
    }
    .header-contact{
        display: none;
    }
    .header-left img {
        cursor: pointer;
        height: 100%;
        width: auto;
    }
    .header-left {
        gap: 0px;
        width: 48%;
    }
    .katalog-list {
        grid-template-columns: repeat(3, 1fr);
    }
    h1, h2 {
        font-size: 38px;
    }
    .slider-left__text-block h2 {
        font-size: 50px;
        line-height: 60px;
    }
    .katalog {
        margin-bottom: 60px;
        margin-top: 50px;
    }
    .katalog-chek {
        justify-content: flex-start;
    }
    .stagesWork-item {
        width: 90%;
    }
    .stagesWork-item_number h3 {
        width: 100%;
        font-size: 24px;

    }
    .news-item {
        padding: 0px 24px;
    }
    .footer_right {
        gap: 5px;
    }
    .footer .container {
        grid-template-columns: 1fr 2fr;
    }
    .menu_list {
        width: 25%;

    }
    .menu_list-2.active {
        left: 25%;
    }
    .menu_list-3.active {
        left: 50%;
    }
    .kontact_tel {
       
        flex-direction: column;
    }
    .kontact_info_contacts {
        max-width: 350px;
    }
    .kontact_info_contacts_tel,.kontact h3 {
        font-size: 20px;
    }
    .kontact_info_contacts_min li span {
        font-size: 12px;
    }
    .avto_body_gallari_prev {
        height: 500px;
    }
    .avto_opis div {
        font-size: 16px;
        line-height: 20px;
    }
    .a1 div {
        font-size: 15px;
        line-height: 16px;
    }    
    .opros .container {
        grid-template-columns: repeat(1,1fr);
        gap: 25px;
    }

    .footer_right {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    .mapSite_container {
        border-top: 1px solid #a5a5a5;
        padding-top: 24px;
    }

    .mapSite.footer_link {
        width: 50%;
    }
}
@media only screen and (max-width : 1100px) {
    .container{
        width: 801px;
    }
    .slider-left__text_mini {
        font-size: 20px;
        line-height: 30px;
    }
    .slider-left__text-block h2 {
        font-size: clamp(28px,1em,38px);
        line-height: 120%;
        margin-bottom: 12px;
    }
    .slider-left__text_opis {
        font-size: clamp(16px,1em,22px);
        line-height: 36px;
        margin-top: 17px;
    }
    .slider {
        height: 350px;
    }
    .slider-item_img_min {
        bottom: -20px;
    }
    .header-left .lang-group{
        display: none;
    }
    .header-right{
        padding: 0;
    }
    .header .container {
        gap: 0px;

    }
    h1, h2 {
        font-size: 25px;
        line-height: 42px;
    }
    .slider-left__text-block {
        height: 311px;
    }
    .slider-chek {
        bottom: 0px;
    }
    .katalog-list_item__img {
        
        height: 160px;
    }    
    .katalog-list_item h3 {
        font-size: 18px;
        line-height: 19px;
    }
    .gallari_items {
        margin-top: 30px;
    }
    
    .katalog-list_button button {
        padding: 15px 30px;
    }
    .stagesWork-item_number {
        width: 100%;
    }
    .stagesWork-item p {
        font-size: 20px;
        line-height: 25px;
    }
    .stagesWork-item_number h3 {
        font-size: 22px;
    }
    .stagesWork-items {
        margin-top: 0px;
        row-gap: 25px;
    }
    .stagesWork .container {
        padding: 45px 0;
    }
    .stagesWork {
        padding: 15px 0;
    }
    .news {
        margin: 15px 0px;
    }
    .news-body_list {
        grid-template-columns: repeat(1,1fr);
    }
    .footer .container {
        grid-template-columns: 1fr;
        gap: 30px;
        justify-items: center;
    }

    .menu_list {
        width: 33%;

    }
    .menu_list-2.active {
        left: 33%;
    }
    .menu_list-3.active {
        left: 66%;
    }
    .connection-button {
        width: 50px;
        height: 50px;
    }
    .connection-button img {
        height: 64%;
        width: auto;
    }
    .header-left img {
        height: 80px;
        margin-right: 20px;
    }
    /* о нас */
    .kompani_info {
        display: flex;
        flex-direction: column-reverse;
        gap: 45px;
    }
    .kompani_advantages_box {
        grid-template-columns: repeat(1,1fr);
    }
    .kompani_info_text {
        padding: 0px;
    }
    .kontact_info_contacts {
        max-width: 100%;
        position: static;
    }
    .avto_body_gallari_prev {
        height: 363px;
    }
    .avto_body_gallari img {
        border-radius: 5px;
    }
    .stagesWork-item_number::after {
        font-size: 50px;
    }    
    .stagesWork-item_number h3 {
        font-size: 16px;
    }
    .opros-body_items {
        flex-direction: column;
    }
    .opros-body_item.active {
        width: 100%;
        height: 230px;
    }
    .opros-body_item {
        
        height: 0px;
    }

    .gallari .popup_img div{
        width: 40%;
    }

    .newsPages_textBig {
        width: 100%;
        font-size: 16px;
        line-height: 23px;
        text-align: left;
        height: auto;
        overflow: unset;
    }

    .newsPages_body {
        display: flex;
        flex-direction: column-reverse;
    }

}

@media only screen and (max-width : 880px) {
    .header-right {
        gap: 0;
    }

    .header-contact_link {
        gap: 16px;
    }
}

@media only screen and (max-width : 830px) {
    .news_gallery_arrow {
        width: 30px;
    }
    .container{
        width: 500px;
    }
    .header .header-right__button{
        display: none;
    }
    .header-contact_link{
        display: none;
    }
    .header-left {
        gap: 0px;
        width: 100%;
    }
    .slider-left__text-block {
        height: 311px;
        width: 100%;
        left: 20px;
        top: 10px;
    }
    .slider_title br{
        display: none;
    }
    .slider-item_img_big {
        border-radius: 0px;
       
    }
    
    .header_slogan {
        width: 240px;
    }
    .slider {
        height: 215px;
    }
    .slider-chek {
        bottom: -20px;
        left: 0px;
    }
    .slider-chek_number {
        font-size: 24px;
    }
    .slider-chek_line {
        width: 25px;
        top: -1px;
    }
    .slider-chek_line.active {
        width: 50px ;
        top: -1px;
    }
    .slider-left__text_mini {
        font-size: 13px;
        line-height: 21px;
    }
    .slider-item_img_min {
        right: 29px;
    }
    h1, h2 {
        font-size: 22px;
        line-height: 40px;
    }
    .katalog-chek_box img {
        height: 100%;
        width: auto;
    }
    .katalog-chek_box:after {
        
        width: 60px;
    }
    .katalog-chek {
        gap: 16px;
    }
    .katalog-chek_box img {
        height: 79%;
        width: auto;
    }
    .stagesWork-items {
        grid-template-columns: repeat(1,1fr);
        row-gap: 0px;
    }
    .footer_right {
        gap: 35px;
        grid-template-columns: repeat(2,1fr);
    }
    .katalog-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .header{
        position: relative;
    }
    .menu_list {
        width: 100%;
    }
    .menu_list-2.active {
        left: 0;
        z-index: 6;
    }
    .menu_list-3.active {
        left: 0;
        z-index: 7;
    }
    ymaps.ymaps-2-1-79-map.ymaps-2-1-79-i-ua_js_yes.ymaps-2-1-79-map-bg.ymaps-2-1-79-islets_map-lang-ru {
        width: 100% !important;
        height: 320px !important;
        z-index: 0;
    }
    ymaps.ymaps-2-1-79-map {
        height: 350px !important;
    }
    .kontact_info_contacts+div{
        height: 350px !important ;
    }

    .avto_body_info {
        padding-left: 12px;
    }
    .a1 div:first-child {
        float: none;

    }
    .avto_body_gallari {
        height: 425px;
    }
    .avto_body{
        display: block;
    }
    .avto_body_gallari {
        position: static;
    }
    .avto_body_info {
        padding-left: 0px;
        padding-top: 35px;
    }
    .connection-fos_body {
       
        width: 100%;
    }
    .gallari_item {
        height: 47vw;
    }
    .gallari_item_boxList {
        grid-gap: 1%;
        grid-template-columns: 18.8% 31% 30% 19.2%;
    }
    .gallari_item_boxList_minbox {
        gap: 5px;
    }
    .gallari_item_boxList {
        height: 34vw;
    }

    .gallari .popup_img div{
        width: 50%;
    }

    .footer .container {
        flex-direction: column;
    }
}

@media only screen and (max-width : 560px) {
    .footer_text_item_mini {
        position: static;
        margin-top: 16px;
    }

    .footer_all_details {
        border-top: 1px solid #a5a5a5;
        padding-top: 22px;
    }

    .mapSite_container {
        flex-direction: column;
        gap: 24px;
    }

    .mapSite.footer_link {
        flex-direction: row;
    }

    .footer .container {
        padding: 20px 10px 60px;
    }
    
    .footer_logo img {
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width : 475px) {
    .news_gallery_arrow {
        width: 24px;
    }
    .container{
        width: 100%;
        padding: 0px 10px;
    }
    .gallari_item_boxList {
        grid-gap: 1%;
        grid-template-columns: 18.8% 31% 30% 19.2%;
    }
    .gallari_item_boxList_minbox {
        gap: 5px;
    }
    .news-item_text {
        font-size: 20px;
    }
    .newsPages_body {
      
        display: flex;
        flex-direction: column-reverse;
    }
    .newsPages_textBig {
        text-align: center;
    }
    .popup_img div {
        width: 98%;
        height: auto;
        padding: 5px;
        background: #fff;
        border-radius: 25px;
    }
    .gallari .popup_img div{
        width: 75%;
        padding: 5px;
    }
    .newsPages_gallari img {
        height: auto;
        width: 100%;
    }
    .gallari_item img {
        width: 100%;
    }
    .katalog-list_item__img img {
        height: 100%;
        width: auto;
    }
    .lineKlient .container {
        gap: 170px;
    }
    .stagesWork-items {
       
        padding: 0px ;
    }
    .newsPages_gallari {
       
        flex-direction: column;
    }
    .slider-left__text-block h2 {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 9px;
    }
    .slider {
        height: 227px;
    }
    .slider-chek_line {
        width: 15px;
        top: 5px;
    }
    .slider-chek_line.active {
        width: 20px ;
        top: 5px;

    }
    .avto_body_gallari {
        height: 320px;
    }
    .avto_body_gallari_prev {
        height: 266px;
    }
    .BreadCrumbs {
        gap: 2px;
        flex-wrap: wrap;
    }
    .slider-chek_number {
        font-size: 18px;
    }
    .slider-item_img_big {
        height: 105%;
    }
    .slider-item_img_min {
        bottom: -11px;
    }
    h1, h2 {
        font-size: 16px;
        line-height: 26px;
    }
    .katalog-list {
        grid-template-columns: repeat(1, 1fr);
    }
    .opros-body_button {
        gap: 25px;
        flex-direction: column-reverse;
    }
    .opros-body_item h3 br{
        display: none;
    }
    .stagesWork-item_number::after {
        font-size: 46px;

    }
    .footer_left {
        flex-direction: column;
        align-items: center;
    }
    .footer_right {
        grid-template-columns: repeat(1,1fr);
    }
    .header-left {
        
        flex-direction: column;
    }
    .header {
        height: 150px;
    }    
    /* о нас */
    .kompani_advantages_ps {
        font-size: 14px;
        line-height: 18px;
    }
    .kompani_info_text h2 {
        font-size: 19px;
        line-height: 21px;
        margin-bottom: 15px;
    }
    
.kompani_info_text p {
    font-size: 16px;
    line-height: 21px;
    margin-top: 10px;
}
.advantages p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}
.advantages h3 {
    text-align: center;
}
.header-right__menu {
    position: absolute;
    right: 0;
    bottom: 22px;
}

}