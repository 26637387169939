.newsPages_body{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding: 50px 0px;
}
.newsPages_prev{
    width: 100%;
    height: auto;
    margin: 20px 0px;
    border-radius: 25px;
}
.newsPages_dates{
    height: 20px;
}
.newsPages_textBig{
    padding-top: 40px;
    width: 95%;
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
}
.newsPages_textBig:has(.overtext){
    height: 800px;
    overflow-y: scroll;
}
.newsPages_textBig::-webkit-scrollbar{
    width: 5px;
    background-color: #363844;
}
.newsPages_textBig::-webkit-scrollbar-thumb{
    background-color: white;
    border: 0.5px solid black;
    
}
.newsPages_textBig span{
    margin-top: 10px;
    display: block;
}
.newsPages_gallari{
    /* display: flex; */
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 10px;
    margin: 50px 0;
    justify-content: center;
    flex-wrap: wrap;
}

.newsPages_gallari img{
    width: 100%;
    /* height: 200px; */
    border-radius: 5px;
    cursor: pointer;
}

.newsPages_gallari_item {
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 200px;
    border-radius: 8px;
}



.photo-gallery-section {
    margin-bottom: 40px;
  }
  
  .photo-gallery-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .photo-gallery-controls h2 {
    margin: 0;
  }
  
  .photo-gallery-controls button {
    font-size: 24px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }

  .gallery-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
  }
  
  .gallery-container > div {
    flex-shrink: 0;
    transition: 0.3s;
  }

  .gallery-container > div:has(.gallery-item.active) {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.7); 
  }

  .gallery-item {
    cursor: pointer;
    width: 312px;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 200px;
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item.active {
    transform: scale(1.2);
  }
  

.popup_img{
    width: 0%;
    height: 100vh;
    position: fixed;
    left: -100px;
    top: 0;
    z-index: 999999;
    background: #00000038;
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup_img div{
  width: 60%;
  height: auto;
  padding: 10px;
  /* background: #fff; */
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallari .popup_img div{
    width: 35%;
    height: auto;
    padding: 10px;
    background: #fff;
    border-radius: 25px;
}
.popup_img img{
  width: 100%;
  height: auto;
  max-width: 900px;
  max-height: 1000px;

  border: 7px solid #fff;
  border-radius: 25px;

}
.popup_img.active{
    width: 100%;
    left: 0;
}

.news_gallery_arrow {
  color: black;
  aspect-ratio: 1;
  width: 40px;
}