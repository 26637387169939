* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans";
}

body {
    /* background: #F5F5F5; */
}

.container {
    margin: 0 auto;
    width: 1600px;
}

.orange {
    color: #ff552f !important;
}

h1,
h2 {
    font-family: "Roboto";
    font-size: 50px;
    /* font-weight: 700; */
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: start;
    color: #363844;
    text-transform: uppercase;
}

.bitum {
    color: #363844;
}

.but:hover {
    transition: 300ms;
    background: #ff552f;
    color: #efefef;
    border: none;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #fff;
}

/* Connection */

.connection-button {
    position: fixed;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    bottom: 50px;
    right: 50px;
    background: linear-gradient(36deg, #363844, #36384475);
    cursor: pointer;
    transition: 300ms;
    border-radius: 50%;
    animation: 3s ease-in-out infinite dreg;
}
@keyframes dreg {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
.connection-button::after {
    content: "";
    position: absolute;
    height: 10%;
    width: 10%;
    border-top: 3px solid;
    border-right: 3px solid;
    border-color: rgb(239 82 47);
    top: 0;
    right: 0;
    z-index: 1;
    transition: 500ms;
    opacity: 0;
    transform: scale(1.6);
}

.connection-button::before {
    content: "";
    position: absolute;
    height: 10%;
    width: 10%;
    border-left: 3px solid;
    border-bottom: 3px solid;
    border-color: rgb(239 82 47);
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: 500ms;
    opacity: 0;
    transform: scale(2);
}

.connection-button:hover::before,
.connection-button:hover::after,
.connection-button:focus {
    animation: pulse 1s;
    height: 96%;
    opacity: 1;
    border-radius: 50%;
    width: 96%;
    transform: scale(1);
}

.connection-button img {
    transition: 300ms;
    width: 66%;
    height: auto;
}

.connection-button:hover img {
    animation: im 1s;
}

@keyframes im {
    0% {
        transform: scale(1.05);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1.02);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1.5);
        height: 10%;
        width: 10%;
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1.02);

        height: 96%;
        width: 96%;
    }
}

.connection-fos {
    position: fixed;
    width: 0%;

    height: 100vh;
    display: flex;
    background: #97878756;
    right: 0;
    top: 0;
    z-index: 99999;
    transition: 300ms;
    overflow: hidden;
    justify-content: flex-end;
}

.connection-fos_body {
    background: #fff;
    height: 100vh;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.connection-fos.active {
    width: 100%;
}

.connection-fos_bodyBox {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.connection-fos_bodyBox img {
    height: 210px;
    width: auto;
}

.connection-fos_bodyBox p {
    color: #999999;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}

.connection-fos_bodyBox input {
    background: none;
    height: 70px;
    width: 100%;
    padding: 10px;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.connection-fos_bodyBox textarea {
    background: none;
    width: 100%;
    padding: 10px;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    border: 2px solid #000;
    resize: vertical;
    height: 100px;
    max-height: 200px;
}
/* BreadCrumbs */
.BreadCrumbs {
    display: flex;
    gap: 25px;

    padding: 27px 0px;
    align-items: flex-end;
}

.breadCrumbs_link {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24.3px;
    color: #003840;
}

.breadCrumbs_link:not(:last-child):after {
    content: "-";
    background: #003840;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 20px;
}

.error {
    margin: 50px 0px;
}

.error .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error img {
    width: 688px;
    height: 451px;
}

.error h2 {
    font-size: 35px;
    font-weight: 700;
    line-height: 39px;
}

.error .but {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
}

.error .but:hover {
    color: #efefef;
}

/* kontact */

.kontact h1 {
    text-align: start;
}

.kontact_info {
    width: 100%;
    position: relative;
    margin-top: 70px;
}

.kontact_info_contacts {
    margin: 10px;
    max-width: 540px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 5;
    background: #fefefe;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.kontact h3 {
    font-family: "Open Sans";
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    color: #363844;
    transition: 300ms;
}

.kontact_info_contacts_min {
    padding: 20px 0px;
}

.kontact h3::after {
    content: "";
    border-top: 1px solid #00000040;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    transition: 300ms;
}
.kontact h3:hover::after {
    border-top: 1px solid #ff552f;
    text-shadow: -1px 6px 14px #00000030;
}
.kontact h3:hover {
    color: #ff552f;
}

.kontact h3 a {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #ff552f;
}

.kontact_info_contacts_tel {
    font-family: "Open Sans";
    font-size: 26px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    color: #363844;
    transition: 300ms;
}
.kontact_info_contacts_tel:hover {
    color: #ff552f;
}

.kontact_info_text {
    font-family: "Open Sans";
}

.kontact_info_contacts_min ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.kontact_info_contacts_min li {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.kontact_info_contacts_min li span {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    color: #2c180d;
    text-align: end;
}

.kontact_info_contacts_min li div {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;
    color: #363844;
    min-width: max-content;
}
.map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.kontact_tel {
    margin: 100px 0px;
    display: flex;
    justify-content: space-evenly;
}
.kontact_tel_min {
    width: 100%;
    margin-top: 35px;
}
.kontact_tel_min h3 {
    font-size: 28px;
    cursor: pointer;
    position: relative;
}
.kontact_tel_min b {
    font-family: "Open Sans";
}
.kontact_tel_min_name {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}
.kontact_tel_min_tel {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    display: block;
}
.kontact_tel_min_mail {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #ff552f;
}
.kontact_contact {
    overflow: hidden;
    height: 0px;
    transition: 300ms;
}
.kontact_contact.active {
    height: 270px;
}
.kontact_rec {
    overflow: hidden;
    height: 0px;
    transition: 300ms;
}
.kontact_rec.active {
    height: 250px;
}
.kontact_rec_head {
    position: relative;
}
.kontact_rec_head::before {
    content: "";
    position: absolute;
    right: 7px;
    width: 10px;
    height: 10px;
    border: 2px solid #000;
    border-top: none;
    border-left: none;
    transform: rotateZ(45deg);
    top: 10px;
}
.kontact_rec_head {
    cursor: pointer;
}
.kontact_rec_head.active::before {
    transform: rotateZ(227deg);
}
/*  */
.bec {
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    display: block;
    margin-top: 20px;
    cursor: pointer;
}
.ps {
    margin-top: 20px;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.beacs {
    position: absolute;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #a5a5a5;
    cursor: pointer;
    top: 10px;
    right: 10px;
    transition: 300ms;
}
.beacs:hover {
    color: #ff552f;
}
.formname {
    font-size: 22px;
    font-weight: 700;
    line-height: 21px;
}

.VIpgJd-ZVi9od-xl07Ob-OEVmcd {
    z-index: 10000002;
    border: none;
    position: fixed;
    box-shadow: 0 3px 8px 2px #999;
}
.skiptranslate {
    display: none !important;
}
body {
    top: 0 !important;
}

/* preloader */
body {
    top: 0 !important;
}

.skiptranslate {
    display: none;
    height: 0;
}

.language__img {
    cursor: pointer;
}
#preloader {
    z-index: 9999999999999999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fdfdfd;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #df5b3d;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #d84321;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ff552f;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.ani {
}
/* низ */
.ani-1 {
    opacity: 0;
    transform: translateY(20px);
}
/* вверх */
.ani-2 {
    opacity: 0;
    transform: translateY(-20px);
}
/* лево */
.ani-3 {
    opacity: 0;
    transform: translateX(-30px);
}
/* право */
.ani-4 {
    opacity: 0;
    transform: translateX(30px);
}
/* выключение */
.ani-1.show,
.ani-2.show,
.ani-3.show,
.ani-4.show {
    opacity: 1;
    transition: all 1.5s;
    transform: translateY(0%);
    transform: translateX(0%);
}

.polsovatelskoe {
    margin: 50px 0px;
}
.polsovatelskoe h1 {
    margin-bottom: 20px;
}
.polsovatelskoe h3 {
    margin: 20px 0px;
}
.polsovatelskoe li {
    margin: 10px 0px;
    list-style-type: none;
    margin-left: 25px;
}
