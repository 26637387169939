.kompani {
}
.kompani_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 50px 0px;
}
.kompani_info img {
    width: 100%;
    height: auto;
    border-radius: 30px;
}
.kompani_info_text {
    padding-left: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}
.kompani_info_text h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #363844;
    text-transform: none;
    margin-bottom: 30px;
}
.opros-body_button.non {
    display: none;
}
.kompani_info_text p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 125%;
    color: #363844;
}

.kompani_advantages_box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 27px;
    margin: 50px 0px;
}
.advantages {
    border-radius: 30px;
    background: #f6f6f6;
    padding: 27px;
}
.advantages h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #363844;
    margin-bottom: 20px;
}
.advantages p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justified;
}
.kompani_advantages_ps {
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin: 100px auto;
    padding: 130px;
    width: 52%;
}

.gen {
    font-size: 14px !important;
}

.kompani_title_and_text {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
}

.kompani_title_and_text .kompani_text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 125%;
    color: #363844;
    width: 47%;
}

.kompani_advantages_ps_main_text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 125%;
    text-align: center;
    color: #000;
    margin-bottom: 24px;
}

.kompani_advantages_ps_standart_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    color: #000;
    margin-bottom: 24px;
}

.become_partner {
    background: #f6f6f6;
}

.kompani_advantages_ps .but {
    margin-top: 0;
}

.kompani_main_picture {
    width: 100%;
    border-radius: 30px;
    margin: 42px 0 100px;
}

.block_number_main_title {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 140%;
    text-align: center;
    color: #2c180d;
    margin-bottom: 100px;
}

.block_number_card_num {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 88px;
    line-height: 120%;
    text-align: center;
    color: #161616;
    margin-bottom: 40px;
}

.block_number_card_text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #161616;
}

.block_number_all_inform {
    width: 75%;
    margin: 0 auto;
}

.block_number_all_cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}

.block_number_card {
    padding: 0 20px;
    border-right: 1px solid #c2c2c2;
    width: 300px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kompani_advantages h2 {
    margin-bottom: 42px;
}

.kompani_advantages_all_inform {
    display: flex;
    gap: 123px;
}

.kompani_advantages_text_inform {
    width: 47%;
}

.kompani_advantages_visible_inf {
    display: flex;
    justify-content: space-between;
}

.kompani_advantages_visible_inf p {
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: start;
    color: #363844;
}

.kompani_advantages_invisible_inf p {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #363844;
}

.kompani_advantages_visible_inf img {
    width: 28px;
    transform: rotate(-45deg);
    transition: 0.3s;
}

.kompani_advantages_invisible_inf {
    padding-bottom: 20px;
}

.kompani_advantages_all_inform summary {
    cursor: pointer;
}

.kompani_advantages_all_inform details {
    border-top: 1px solid #a5a5a5;
}

.kompani_advantages_text_inform details:last-child {
    border-bottom: 1px solid #a5a5a5;
}

.kompani_advantages_all_inform details summary {
    cursor: pointer;
    transition: 0.5s ease-out;
}

.kompani_advantages_all_inform details[open] summary {
    margin-bottom: 20px;
}

.kompani_advantages_all_inform details[open] img {
    transform: rotate(45deg);
}

.kompani_advantages_picture img {
    width: 100%;
    border-radius: 30px;
}

.baner_mobile {
    display: none;
}

@media screen and (max-width: 1400px) {
    .block_number_all_inform {
        width: 100%;
    }

    .kompani_title_and_text .kompani_text {
        width: 50%;
    }

    .kompani_advantages_ps {
        width: 70%;
    }

    .kompani_advantages_all_inform {
        gap: 90px;
    }

    .block_number_main_title {
        font-size: 30px;
    }
}

@media screen and (max-width: 1100px) {
    .kompani_title_and_text {
        margin-top: 50px;
    }

    .kompani_title_and_text .kompani_text {
        width: 67%;
    }

    .block_number_card {
        border: none;
    }

    .block_number_all_cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .block_number_main_title,
    .block_number_all_cards {
        margin-bottom: 40px;
    }

    .block_number_card {
        height: 210px;
        width: auto;
    }

    .kompani_advantages_ps {
        width: 95%;
    }

    .kompani_advantages_all_inform {
        flex-direction: column;
    }

    .kompani_advantages_text_inform {
        width: 100%;
    }

    .kompani_advantages_visible_inf p {
        font-size: 20px;
    }
}

@media screen and (max-width: 830px) {
    .kompani_title_and_text {
        flex-direction: column;
    }

    .kompani_title_and_text .kompani_text {
        width: 100%;
        margin-top: 20px;
    }

    .kompani_advantages_ps {
        padding: 70px;
        width: 100%;
    }

    .become_partner .news_button {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .block_number_all_cards {
        grid-template-columns: 1fr;
    }

    .kompani_title_and_text {
        margin-top: 23px;
    }

    .kompani_main_picture {
        margin: 42px 0 50px;
    }

    .block_number_card_num {
        margin-bottom: 16px;
    }

    .kompani_info_text {
        padding: 0px;
    }

    .kompani_advantages_ps {
        margin: 50px auto;
    }

    .kompani_advantages_all_inform {
        gap: 50px;
    }

    .kompani_info {
        margin: 0;
    }

    .marquee {
        margin: 50px 0;
    }

    .kompani_title_and_text .kompani_text,
    .block_number_main_title,
    .kompani_advantages_ps_main_text {
        font-size: 20px;
    }

    .kompani_advantages_visible_inf p {
        font-size: 18px;
        font-weight: 400;
    }

    .baner_mobile {
        display: block;
    }

    .baner_comp {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .kompani_advantages_ps {
        padding: 70px 20px;
    }
}
