.header {
    height: 120px;

    /* background: #2C180D; */
   color: #363844;
   background: #fff;

}

.header a {
    color: #363844;

}
.header .container{
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    height: 100%;
}
.header_slogan {
    font-family: "Open Sans";
    font-size: 17px;
    font-weight: unset;
    width: 310px;
    line-height: normal;

}
.header_slogan span {
    font-size: 34px;
    font-weight: bold;
    font-family: 'Open Sans';
    text-align: left;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 33px;
    height: 100%;
}

.header-left img {
    cursor: pointer;
}

.header-right {
    display: flex;
    align-items: center;
    font-family: "Disket-Mono";
    padding-left: 50px;
    gap: 5px;

}
.header_logo{
    overflow: hidden;
    position: relative;
    transition: 300ms;
    height: 100%;

}
.header_logo img{
    height: 100%;

}
.header_logo:hover{
    transform: scale(0.9);
}
.header_logo:after {
	content: "";
	display: block;
	width: 20px;
	height: 200px;
	margin-left: 50px;
	background: #fff;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, .5)), color-stop(50%, #fff), to(rgba(255, 255, 255, .5)));
	background: -o-linear-gradient(top, rgba(255, 255, 255, .5) 0, #fff 50%, rgba(255, 255, 255, .5) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, .5) 0, #fff 50%, rgba(255, 255, 255, .5) 100%);
	left: -40px;
	top: -100px;
	z-index: 1;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	position: absolute;
	-webkit-animation: 2s ease-in-out infinite movingFlare;
	animation: 2s ease-in-out infinite movingFlare;
}
@-webkit-keyframes movingFlare {
	0% {
		left: -30px;
		margin-left: 0
	}

	100%,
	30% {
		left: 110%;
		margin-left: 80px
	}
}

@keyframes movingFlare {
	0% {
		left: -30px;
		margin-left: 0
	}

	100%,
	30% {
		left: 110%;
		margin-left: 80px
	}
}




.header-right__location {
    color: #FF552F !important;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}
.header-right__location::before{
    content: url(/public/img/local.svg);
}
.header-right__phone {
    margin-right: 20px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Open Sans';
    transition: 300ms;
    position: relative;
}
.header-right__phone::after{
    
    content: "";
    position: absolute;
    background: #FF552F;
    height: 2px;
    width: 0%;
    transition: 300ms;
    left: 0px;
    bottom: 0px;
}
.header-right__phone:hover::after{
    
    
    width: 100%;
}
.header-contact_link {
    display: flex;
    justify-content: center;
    gap: 22px;
    margin: 0px 25px;
}
.header-contact_link a img{
    height: 35px;
    width: 35px;
    transition: 300ms;
    padding: 5px;
    border: 1.9px solid #000;
    border-radius: 10px;

}
.header-contact_link a img:hover{
    transform: scale(1.3);
    
}
.header-right__phone:hover span {
    color: #FF552F;
    transition: 300ms;

}

.header-right__button {

    width: 207px;
    height: 43px;


    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    background: #fff;
    border-radius: 25px;
    border: 0.93px solid #4B4B4B;
    overflow: hidden;
    
}

.header-right__button span {
    color: #363844;
    transition: color 0.5s cubic-bezier(0.250, 0.100, 0.250, 1.000);
    filter: brightness(0);
    transition: 50ms;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    
}


.header-right__button:hover span {
    color: #EAEAEA;
    position: inherit;
    filter: brightness(1);
}

.header-right__button img {
    filter: brightness(0);
    transition: 50ms;
}

.header-right__button:hover img {
    position: inherit;
    filter: brightness(1);
}

.header-right__button span, .header-right__button img {
    z-index: 2;
}

.header-right__button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #FF552F;
    width: 0%;
    height: 100%;
    z-index: 1;
    transition: 500ms;

}

.header-right__button:hover:after {
    width: 100%;
}

.header-right__menu {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* margin-right: 16px; */
    cursor: pointer;
}
.header-right__menu-text{
    font-family: "Open Sans";
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    transform: rotateZ(90deg);
    position: relative;
    left: 0px;
    top: -8px;
}
.lang-group {
   
    gap: 5px;
    height: 100%;
    cursor: pointer;
    filter: grayscale(1);
    transition: 300ms;
    position: relative;
    width: 50px;
    height: 43px;
    z-index: 9;
}
.lang{
    
}
.lang-group2{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height:  0px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: 300ms;
    width: 100%;
    gap: 5px;
}
.lang-group:hover .lang-group2{
    filter: grayscale(0);
    height: 80px;
}
.lang-group:hover{
    background: none !important;
}
.lang-group2:hover{
    padding: 5px;
    border-radius: 3px;
}
.lang-group:hover{
    filter: grayscale(0);
    
}
.en{
    background: url(/public/img/eng.png);
}
.ru{
    background: url(/public/img/ru.png);
}
.lang-group::after {
    content: url(/public/img/tr.svg);
    transform: rotateZ(90deg);
    position: absolute;
    right: -10px;
}
.lang-group img {
   
   
}
.lang-group2 img{
    width: 100%;
    height: 40px;
}
.lang-group__eng {
    background-image: url(/public/img/en.png);
    width: 32px;
    height: 19px;
    position: relative;
    cursor: pointer;
    opacity: 0.5;

}

.lang-group__rus {
    background-image: url(/public/img/ru.png);
    width: 28px;
    height: 20px;
    position: relative;
    cursor: pointer;
    opacity: 0.5;

}

.lang-group__eng:after {
    content: "ENG";
}

.lang-group__rus:after {
    content: "RUS";
}

.lang-group__eng:after, .lang-group__rus:after {
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 7px;
    font-weight: 700;
    color: #A5A5A5;
}

.active_leng {
    opacity: 1;

}

.bar {
    height: 3px;
    background-color: #363844;
    display: block;
    transition: 0.3s ease;
    margin-top: 5px;
    border-radius: 2px;
}

.bar:nth-child(1) {
    width: 100%;

}

.bar:nth-child(2) {
    width: 70%;

}

.bar:nth-child(3) {
    width: 45%;

}

#menu-bar {
    width: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    left: 10px;
}

.header-right__menu:hover .bar {
    width: 100%;
}

.menu {
    position: fixed;
    width: 0%;
    height: 100vh;
    z-index: 999;
    top: 0;
    left: -999px;
    transition: 300ms;
    display: flex;
    justify-content: flex-start;
}
.menu.active{
    left: 0px;
    width: 100%;

}

.menu_list {
    width: 20%;
    height: 100vh;
    background: #EAEAEA;
    border: 1px solid #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #4B4B4B;
    padding: 40px;
    z-index:5;
    position: absolute;
    transition: 300ms;

}

.menu_list nav {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 50px;
}

.menu_list nav a,.menu_list nav p {
    color: #4B4B4B;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    transition: 300ms;
    cursor: pointer;
}

.menu_list nav a:hover,.menu_list nav p:hover {
    color: #FF552F;
}

.menu .lang-group__eng:after, .menu .lang-group__rus:after {

    color: #000;
}

.menu_sity {
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #4B4B4B;
    margin: 20px 0px;
}

.menu .header-right__phone {
    font-size: "Open Sans";
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  
    
}
.menu .header-right__phone::after {
    content: "";
    width: 0%;
    height: 2px;
    background: #FF552F;
    transition: 300ms;
    position: absolute;
    left: 0;
    bottom: -3px;
}
.menu .header-right__phone:hover::after{
    width: 100%;
}
.header-contact {
    display: flex;
    flex-direction: column;
}
.header-right__mail{
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    

}
.menu .mail{
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #363844;

}
.menu_contact{
    display: flex;
    gap: 10px;
    margin-top: 20px;
}
.menu_contact .footer-menu_contact{
    border-color: #4B4B4B;
    transition: 300ms;
}
.menu_contact .footer-menu_contact:hover{
    border-color: #FF552F;
    transform: scale(1.3);
}

.menu_contact img{
    transition: 300ms;

    /* filter: brightness(0.5); */
}
.menu_contact .footer-menu_contact:hover  img{
    filter: brightness(0.2);

}
.menu_list-1 {}
.menu_list-2 {
    z-index: 4;
    left: 0%;
}
.menu_list-3 {
    z-index: 3;
    left: 0%;
}

.menu_list-2.active{
    left: 20%;
    transition: 300ms;

}
.menu_list-3.active{
    left: 40%;
    transition: 300ms;

}
.menu_list-3 a{
font-family: "Open Sans";
font-size: 18px;
font-weight: 400;
line-height: 24px;

}

.exit_menu{
    position: relative;
    top: -20px;
    padding-bottom: 100px;
    cursor: pointer;
}